'use strict';

Gri.module({
  name: 'section-alpha',
  ieVersion: null,
  $el: $('.section-alpha'),
  container: '.section-alpha',
  fn: function () {
    
  }
});
